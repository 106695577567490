<template>
  <div>
    <SearchPanel
      flex
      show-btn
      size="mini"
      @getList="onSearch"
    >
      <div>
        <p>
          订货退货单号
        </p>
        <el-input
          v-model.trim="searchData.orderNo"
          placeholder="请输入订货退货单号"
          size="mini"
          clearable
        />
      </div>
      <div>
        <p>
          类型
        </p>
        <el-select
          v-model="searchData.type"
          size="mini"
          placeholder="请选择是否全部发货"
          filterable
          clearable
          @change="getSearchList()"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value + 'type'"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div>
        <p>
          关联订货单号
        </p>
        <el-input
          v-model.trim="searchData.associated"
          placeholder="请输入订货退货单号"
          size="mini"
          clearable
        />
      </div>
      <div v-if="isMemberStore">
        <p>
          供应商名称
        </p>
        <el-input
          v-model.trim="searchData.supplierName"
          placeholder="请输入供应商名称"
          size="mini"
          clearable
        />
      </div>
      <div>
        <p>
          物流公司
        </p>
        <el-select
          v-model="searchData.logisticsCompany"
          size="mini"
          placeholder="请选择是否全部发货"
          filterable
          clearable
          @change="getSearchList()"
        >
          <el-option
            v-for="item in logisticsCompanyOptions"
            :key="item.label + 'logisticsCompany'"
            :label="item.label"
            :value="item.label"
          />
        </el-select>
      </div>
      <div>
        <p>
          运单号
        </p>
        <el-input
          v-model.trim="searchData.logisticsNo"
          placeholder="请输入订货退货单号"
          size="mini"
          clearable
        />
      </div>
      <div>
        <p>
          创建时间
        </p>
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          size="mini"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        />
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="resetSearch"
      >
        重 置
      </el-button>
      <el-button
        slot="button-right"
        type="primary"
        size="mini"
        :loading="downLoading"
        @click="download"
      >
        导 出
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="loading"
      :data="tableData"
      class="tableBox"
      :cell-style="{
        background: '#FAFAFA'
      }"
    >
      <el-table-column
        label="订货退货单号"
        min-width="200"
      >
        <template slot-scope="{row}">
          <span>{{ row.refundNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型">
        <template slot-scope="{row}">
          <span>{{ row.refundTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="关联订货单"
        min-width="200"
      >
        <template slot-scope="{row}">
          <span>{{ row.supplyOrderNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isMemberStore"
        label="供应商名称"
        width="120"
      >
        <template slot-scope="{row}">
          <span>{{ row.supplierName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="订货退货单状态"
        width="120"
      >
        <template slot-scope="{row}">
          <span>{{ row.refundStateName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="退款金额（元）"
        width="120"
      >
        <template slot-scope="{row}">
          <span>{{ row.refundAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="物流公司">
        <template slot-scope="{row}">
          <span>{{ row.logisticName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="运单号"
        width="150"
      >
        <template slot-scope="{row}">
          <span>{{ row.logisticNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        min-width="160"
      >
        <template slot-scope="{row}">
          <span>{{ formatTime(row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="100"
        fixed="right"
      >
        <template slot-scope="{row}">
          <el-button
            size="mini"
            type="primary"
            @click="handleDetail(row.id)"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script setup>
import {
  ref, getCurrentInstance, onMounted,
} from 'vue';
import { set } from 'lodash';
import dayjs from 'dayjs';
import { downloadFile } from '@/utils/download';

const app = getCurrentInstance().proxy;
const loading = ref(false);
const searchData = ref({
  beginTime: '', // 查询-下单时间
  endTime: '', // 查询-下单时间
  orderNo: '', // 查询-订单编号
  type: '', // 查询-订单类型
  associated: '', // 关联订单编号
  logisticsCompany: '', // 物流公司
  logisticsNo: '', // 物流单号
  supplierName: '', // 查询-供应商名称
});
const logisticsCompanyOptions = ref([]);
const typeOptions = ref([
  {
    label: '全部',
    value: '',
  },
  {
    label: '仅退款',
    value: 1,
  },
  {
    label: '退货退款',
    value: 2,
  },
]);
const downLoading = ref(false);
const currentPage = ref(1); // 分页
const pageSize = ref(10);
const dateRange = ref([new Date().setMonth(new Date().getMonth() - 1), new Date().getTime()]);
const tableData = ref([]);
const count = ref(0);
const selection = ref({});
const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));

// 获取快递
const getLogisticList = () => {
  app.$axios.get(app.$api.commonNew.getLogisticList, {
    params: {
      // type: orderData.shippingType,
    },
  }).then((res) => {
    logisticsCompanyOptions.value = res.data.map((item) => {
      return {
        label: item.logisticName,
        value: item.logisticId,
      };
    }) || [];
  });
};

const getParams = () => {
  let params = {
    // 订货退货单号
    refundNo: searchData.value.orderNo,
    // 订单类型
    refundType: searchData.value.type,
    // 关联订单编号
    supplyOrderNo: searchData.value.associated,
    // 物流公司
    logisticName: searchData.value.logisticsCompany,
    // 物流单号
    logisticNo: searchData.value.logisticsNo,
    // 供应商名称
    supplierName: searchData.value.supplierName,
    currentPage: currentPage.value,
    pageSize: pageSize.value,
  };
  if (dateRange.value && dateRange.value.length > 0) {
    set(params, 'createBeginTime', dateRange.value[0]);
    set(params, 'createEndTime', dateRange.value[1]);
  }
  // if (notDeliveryType.value) set(params, 'notDeliveryType', notDeliveryType.value);

  return params;
};

const getSearchList = () => {
  let params = getParams();
  loading.value = true;
  app.$axios
    .get(app.$api.supplierOrder.page, { params })
    .then((res) => {
      tableData.value = res.data.result;
      count.value = Number(res.data.total);
      loading.value = false;
      selection.value = {};
      // currentOrder.value = '';
    })
    .finally(() => {
      loading.value = false;
    });
};

const onSearch = () => {
  // 修改搜索条件时把页码重置为1
  currentPage.value = 1;
  getSearchList();
};

const resetSearch = () => {
  dateRange.value = [new Date().setMonth(new Date().getMonth() - 1),
    new Date().getTime()];// 查询-时间范围
  // 初始化搜索条件
  searchData.value = {
    beginTime: '', // 查询-下单时间
    endTime: '', // 查询-下单时间
    orderNo: '', // 查询-订单编号
    type: '', // 查询-订单类型
    // 关联订单编号
    associated: '',
    // 物流公司
    logisticsCompany: '',
    // 物流单号
    logisticsNo: '',
    supplierName: '', // 查询-供应商名称
  };
};

const formatTime = (time) => {
  return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
};

const handleDetail = (id) => {
  app.$router.push({
    path: '/supplyChain/returnOrder/detail',
    query: {
      orderId: id,
    },
  });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  currentPage.value = 1;
  getSearchList();
};

const handleCurrentChange = (val) => {
  currentPage.value = val;
  getSearchList();
};

const download = async () => {
  app.$message.success('正在导出数据，请稍后前往浏览器下载列表查看下载文件');
  let params = getParams();
  downLoading.value = true;
  const res = await app.$axios.post(app.$api.supplierOrder.export, params, { responseType: 'blob' });
  downLoading.value = false;
  downloadFile(res, `订货退货单导出_${dayjs(new Date()).format('YYYY-MM-DD')}_${dayjs(new Date()).format('HH-mm-ss')}.xls`);
};

onMounted(() => {
  getSearchList();
  getLogisticList();
});
</script>

<style lang="scss" scoped>
.tableBox{
  :deep(.el-table__header){
    .el-table__cell{
      font-weight: bold;
      color: #333;
    }
  }
  :deep(.el-table__row){
    font-size: 12px;
  }
}
.goods-card {
      min-width: 10px;
      width: 100%;
      display: flex;
      img {
        margin-top: 8px;
        width: 88px;
        height: 88px;
      }
      .content {
        padding: 0 12px;
        line-height: 28px;
        .sku{
          color: #736E6E;
        }
      }
    }
</style>

<style scoped>
/* .tableBox >>> .el-table__expand-icon:after {
  content: '';
}
.tableBox >>> .el-table__expand-icon > i {
  display: none;
} */
/* .tableBox >>> .el-table__expand-column {
  border-right: none;
} */
.tableBox >>> .el-table__expanded-cell[class*=cell] {
  padding: 5px 50px !important;
}
</style>
