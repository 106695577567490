var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "SearchPanel",
        {
          attrs: { flex: "", "show-btn": "", size: "mini" },
          on: { getList: _setup.onSearch },
        },
        [
          _c(
            "div",
            [
              _c("p", [_vm._v(" 订货退货单号 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入订货退货单号",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _setup.searchData.orderNo,
                  callback: function ($$v) {
                    _vm.$set(
                      _setup.searchData,
                      "orderNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "searchData.orderNo",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 类型 ")]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    placeholder: "请选择是否全部发货",
                    filterable: "",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _setup.getSearchList()
                    },
                  },
                  model: {
                    value: _setup.searchData.type,
                    callback: function ($$v) {
                      _vm.$set(_setup.searchData, "type", $$v)
                    },
                    expression: "searchData.type",
                  },
                },
                _vm._l(_setup.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value + "type",
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 关联订货单号 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入订货退货单号",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _setup.searchData.associated,
                  callback: function ($$v) {
                    _vm.$set(
                      _setup.searchData,
                      "associated",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "searchData.associated",
                },
              }),
            ],
            1
          ),
          _setup.isMemberStore
            ? _c(
                "div",
                [
                  _c("p", [_vm._v(" 供应商名称 ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入供应商名称",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _setup.searchData.supplierName,
                      callback: function ($$v) {
                        _vm.$set(
                          _setup.searchData,
                          "supplierName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "searchData.supplierName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 物流公司 ")]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    placeholder: "请选择是否全部发货",
                    filterable: "",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _setup.getSearchList()
                    },
                  },
                  model: {
                    value: _setup.searchData.logisticsCompany,
                    callback: function ($$v) {
                      _vm.$set(_setup.searchData, "logisticsCompany", $$v)
                    },
                    expression: "searchData.logisticsCompany",
                  },
                },
                _vm._l(_setup.logisticsCompanyOptions, function (item) {
                  return _c("el-option", {
                    key: item.label + "logisticsCompany",
                    attrs: { label: item.label, value: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 运单号 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入订货退货单号",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _setup.searchData.logisticsNo,
                  callback: function ($$v) {
                    _vm.$set(
                      _setup.searchData,
                      "logisticsNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "searchData.logisticsNo",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 创建时间 ")]),
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  size: "mini",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "timestamp",
                },
                model: {
                  value: _setup.dateRange,
                  callback: function ($$v) {
                    _setup.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "button-right", size: "mini" },
              on: { click: _setup.resetSearch },
              slot: "button-right",
            },
            [_vm._v(" 重 置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                slot: "button-right",
                type: "primary",
                size: "mini",
                loading: _setup.downLoading,
              },
              on: { click: _setup.download },
              slot: "button-right",
            },
            [_vm._v(" 导 出 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _setup.loading,
              expression: "loading",
            },
          ],
          staticClass: "tableBox",
          attrs: {
            data: _setup.tableData,
            "cell-style": {
              background: "#FAFAFA",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "订货退货单号", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.refundNo))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.refundTypeName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "关联订货单", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.supplyOrderNo))])]
                },
              },
            ]),
          }),
          _setup.isMemberStore
            ? _c("el-table-column", {
                attrs: { label: "供应商名称", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [_c("span", [_vm._v(_vm._s(row.supplierName))])]
                      },
                    },
                  ],
                  null,
                  false,
                  101953751
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "订货退货单状态", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.refundStateName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "退款金额（元）", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.refundAmount))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "物流公司" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.logisticName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "运单号", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.logisticNo))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_setup.formatTime(row.createTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _setup.handleDetail(row.id)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _setup.currentPage,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _setup.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _setup.count,
        },
        on: {
          "size-change": _setup.handleSizeChange,
          "current-change": _setup.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }