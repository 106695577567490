<template>
  <div class="app-container">
    <div class="outer">
      <el-tabs
        v-model="activeName"
        type="border-card"
      >
        <el-tab-pane
          label="直发"
          name="1"
        >
          <OrderList v-if="activeName === '1'" />
        </el-tab-pane>

        <!-- <el-tab-pane
          label="到仓"
          name="2"
        >
          <import-order v-if="activeName === '2'" />
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import OrderList from './orderList.vue';

export default {
  name: '',
  components: {
    OrderList,
  },
  props: {},
  data() {
    return {
      activeName: '1',
    };
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
</style>
